/*
 * Fonts
 * ========================================================================== */

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 35 Light'), local('AvenirLTStd-Light'),
    url('../public/fonts/AvenirLTStd-Light.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-Light.woff') format('woff');
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 45 Book'), local('AvenirLTStd-Book'),
    url('../public/fonts/AvenirLTStd-Book.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 55 Roman'), local('AvenirLTStd-Roman'),
    url('../public/fonts/AvenirLTStd-Roman.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-Roman.woff') format('woff');
  font-weight: 550;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 65 Medium'), local('AvenirLTStd-Medium'),
    url('../public/fonts/AvenirLTStd-Medium.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-Medium.woff') format('woff');
  font-weight: 650;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 85 Heavy'), local('AvenirLTStd-Heavy'),
    url('../public/fonts/AvenirLTStd-Heavy.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 95 Black'), local('AvenirLTStd-Black'),
    url('../public/fonts/AvenirLTStd-Black.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-Black.woff') format('woff');
  font-weight: 950;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 35 Light Oblique'), local('AvenirLTStd-LightOblique'),
    url('../public/fonts/AvenirLTStd-LightOblique.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-LightOblique.woff') format('woff');
  font-weight: 350;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 45 Book Oblique'), local('AvenirLTStd-BookOblique'),
    url('../public/fonts/AvenirLTStd-BookOblique.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-BookOblique.woff') format('woff');
  font-weight: 450;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 55 Oblique'), local('AvenirLTStd-Oblique'),
    url('../public/fonts/AvenirLTStd-Oblique.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-Oblique.woff') format('woff');
  font-weight: 550;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 65 Medium Oblique'), local('AvenirLTStd-MediumOblique'),
    url('../public/fonts/AvenirLTStd-MediumOblique.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-MediumOblique.woff') format('woff');
  font-weight: 650;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 85 Heavy Oblique'), local('AvenirLTStd-HeavyOblique'),
    url('../public/fonts/AvenirLTStd-HeavyOblique.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-HeavyOblique.woff') format('woff');
  font-weight: 850;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir LT Std 95 Black Oblique'), local('AvenirLTStd-BlackOblique'),
    url('../public/fonts/AvenirLTStd-BlackOblique.woff2') format('woff2'),
    url('../public/fonts/AvenirLTStd-BlackOblique.woff') format('woff');
  font-weight: 950;
  font-style: italic;
}
